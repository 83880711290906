import React, { Component } from 'react'
import { Typography, Button, ButtonGroup, Popover, Menu, MenuItem, withStyles } from '@material-ui/core'
import { ChromePicker } from 'react-color'
import SaveIcon from '@material-ui/icons/Save'
import BackIcon from '@material-ui/icons/ArrowBack'


const style = {
    colorBlock: {
        width: '20px',
        height: '20px',
        borderRadius: '5px',
    },
    themeMenu: {
        position: 'fixed',
        right: '30px',
        bottom: '30px',
        zIndex: 1000,
    }
}


class T extends Component {
    state = {
        anchorEl: null
    }

    handleClick = name => event => {
        this.setState({
            anchorEl: event.currentTarget,
            editing: name,
        })
    }

    handleClose = () => {
        this.setState({anchorEl: null})
    }

    handleChangeComplete = color => {
        this.props.onChange(this.state.editing, color.hex)
    }

    handleFontSelect = event => {
        this.props.onChange('font', event.target.textContent)
        this.handleClose()
    }

    handleBack = () => {
        window.history.back()
    }

    render() {
        const { classes, primary, secondary, font } = this.props
        const { anchorEl } = this.state

        const open = Boolean(anchorEl)
        const id = open ? 'simple-popover' : undefined

        return (
            <div>
                <ButtonGroup className={classes.themeMenu} variant="contained" size="small" aria-label="Small contained button group">
                    <Button onClick={this.handleClick('primary')}><div className={classes.colorBlock} style={{backgroundColor: primary}}></div></Button>
                    <Button onClick={this.handleClick('secondary')}><div className={classes.colorBlock} style={{backgroundColor: secondary}}></div></Button>
                    <Button onClick={this.handleClick('font')} style={{textTransform: 'none'}}><Typography>{font}</Typography></Button>
                    <Button onClick={this.props.onSave}><SaveIcon color="primary" /></Button>
                    <Button onClick={this.handleBack}><BackIcon color="primary" /></Button>
                </ButtonGroup>
                <Popover
                    id={id}
                    open={open && this.state.editing !== 'font'}
                    anchorEl={anchorEl}
                    onClose={this.handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <ChromePicker color={this.props[this.state.editing]} onChangeComplete={this.handleChangeComplete}/>
                </Popover>
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl) && this.state.editing === 'font'}
                    onClose={this.handleClose}
                >
                    <MenuItem onClick={this.handleFontSelect}>Arial</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Avenir Next</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Roboto</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Helvetica</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Times New Roman</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Times</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Verdana</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Georgia</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Palatino</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Garamond</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Bookman</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Comic Sans MS</MenuItem>
                    <MenuItem onClick={this.handleFontSelect}>Trebuchet MS</MenuItem>
                </Menu>
            </div>
        )
    }
}


export const ThemeMenu = withStyles(style)(T)
