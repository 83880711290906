import React, { Component } from 'react'
import { Payment } from './Payment'
import { Typography, InputBase, Container, Grid, withStyles, createMuiTheme } from '@material-ui/core'
import { withSnackbar } from 'notistack'
import { MuiThemeProvider } from '@material-ui/core/styles'
import { ThemeMenu } from './ThemeMenu'
import EditIcon from '@material-ui/icons/Edit'
import { Redirect } from 'react-router-dom'
import { FixedSpace, SnackMessage, Line } from 'Utils'
import Markdown from 'react-markdown'
import classnames from 'classnames'
import refundIcon from './refund.svg'
import secureIcon from './secure.svg'
import processIcon from './process.svg'
import { networking } from 'Models'
import './ItemPreview.css'


const style = {
    sectionTitle: {
        borderBottom: '1px solid #aaa',
        marginBottom: '30px',
        paddingBottom: '10px',
    },
    center: {
        textAlign: 'center',
    },
    header: {
        marginTop: '50px',
        fontWeight: 500,
    },
    imageTitle: {
        fontWeight: 500,
    },
    sectionMargin: {
        marginBottom: '50px',
    },
    hintItem: {
        marginTop: '30px',
        height: '30px',
    },
    editable: {
        position: 'relative',
        '& .edit-text-field': {
            width: '100%',  
            border: '1px dotted #22E',
        },
        '& .Mui-focused': {
            border: '1px solid #22E',
        }
    },
    imageButton: {
        position: 'relative',
        '& .image-edit-icon': {
            opacity: 0,
            position: 'absolute',
            right: '-30px',
            transition: '0.3s',
        },
        '&:hover .image-edit-icon': {
            opacity: 1,
        }
    },
    markdown: {
        border: '1px dotted #22E',
        '& .edit-text-field': {
            position: 'absolute',
            width: 0,
            height: 0,
            border: 'none',
        },
        '& .Mui-focused': {
            display: 'block',
        },
    },
    markdownFocused: {
        '& .edit-text-field': {
            border: '1px solid #22E',
        },
        '& .markdown-content': {
            display: 'none',
        }
    },
    priceHint: {
        position: 'relative',
        '& .price-hint': {
            opacity: 0,
            position: 'absolute',
            right: '-30px',
            transition: '0.3s',
        },
        '&:hover .price-hint': {
            opacity: 1,
        }
    }
}


class P extends Component {
    constructor(props) {
        super(props)
        this.state = {
            'markdown-editor-desc': false,
            'markdown-editor-s-desc': false,
        }
        if (props.item) {
            this.state = {
                ...this.state,
                id: props.item.id,
                title: props.item.title,
                image:props.item.image,
                image_title: props.item.image_title,
                short_desc: props.item.short_desc,
                long_desc: props.item.long_desc,
                primary: props.item.primary_color,
                secondary: props.item.secondary_color,
                font: props.item.font,
                amount: props.item.amount,
                s_title: props.item.s_title,
                s_desc: props.item.s_desc,
                s_button_title: props.item.s_button_title,
                s_button_href: props.item.s_button_href,
                s_image: props.item.s_image,
            }
        }
    }

    handleMarkdownClick = id => () => {
        document.getElementById(id).focus()
        this.setState({[id]: true})
    }

    handleMarkdownFocusOut = id => event => {
        event.target.parentNode.classList.remove('Mui-focused')
        this.setState({[id]: false})
    }

    handleImageButton = name => () => {
        if (this.state.id) {
            document.getElementById(name).click()
        } else {
            this.props.enqueueSnackbar('Please save once first then upload image', {
                variant: 'warning',
            })
        }
    }

    handleImageChange = name => event => {
        if (name === 'image') {
            networking.uploadPerkImage(this.state.id, event.target.files[0]).then(response => {
                this.setState({image: response.image})
            })
        } else {
            networking.uploadSuccessImage(this.state.id, event.target.files[0]).then(response => {
                this.setState({s_image: response.image})
            })
        }
        
        event.target.value = ''
    }

    handleChange = name => event => {
        this.setState({[name]: event.target.value})
    }

    handleThemeChange = (name, value) => {
        this.setState({[name]: value})
    }

    handleSave = () => {
        const data = {
            title: this.state.title,
            image_title: this.state.image_title,
            short_desc: this.state.short_desc,
            long_desc: this.state.long_desc,
            primary_color: this.state.primary,
            secondary_color: this.state.secondary,
            font: this.state.font,
            amount: this.state.amount,
            s_title: this.state.s_title,
            s_desc: this.state.s_desc,
            s_button_title: this.state.s_button_title === '' ? null : this.state.s_button_title,
            s_button_href: this.state.s_button_href === '' ? null : this.state.s_button_href,
        }

        networking.createOrPatchItem(this.state.id, data).then(response => {
            this.setState({id: response.id})
            this.props.enqueueSnackbar('Saved', {
                variant: 'success',
            })
        }).catch(error => {
            this.props.enqueueSnackbar('', {
                variant: 'error',
                children: (key) => (
                    <SnackMessage id={key} title="Failed to save" messages={error.response.data} />
                ),
            })
        })
    }

    render() {
        const { item, classes } = this.props
        const { title, image, image_title, short_desc, long_desc, primary, secondary, font, amount } = this.state
        const { s_title, s_desc, s_button_title, s_button_href, s_image } = this.state
        
        if (item === null) {
            return (<Redirect push to="/reservations/item/list/" />)
        }

        const theme = createMuiTheme({
            typography: {
                useNextVariants: true,
                fontFamily: [
                    font, 
                    '-apple-system', 
                    'BlinkMacSystemFont', 
                    'Segoe UI', 'Roboto', 'Oxygen',
                    'Ubuntu', 'Cantarell', 'Fira Sans', 
                    'Droid Sans', 'Helvetica Neue',
                ]
            },
            palette: {
                primary: {
                    main: primary,
                },
                secondary: {
                    main: secondary,
                }
            }
        })

        return (
            <MuiThemeProvider theme={theme}>
                <ThemeMenu 
                    primary={primary} 
                    secondary={secondary} 
                    font={font} 
                    onChange={this.handleThemeChange} 
                    onSave={this.handleSave}
                />
                <Container maxWidth="lg">
                    <FixedSpace size="lg" />
                    <Typography variant="h4" className={classnames(classes.center, classes.header, classes.sectionMargin, classes.editable)}>
                        <InputBase className="edit-text-field" onChange={this.handleChange('title')} style={{fontSize: '1em'}} inputProps={{ 'aria-label': 'naked', style: {textAlign: 'center'} }} value={title} />
                    </Typography>
                    <Grid container direction="row" spacing={5} className={classes.sectionMargin}>
                        <Grid item xs={3}>
                            <div className={classes.imageButton} style={{width: '100%'}} onClick={this.handleImageButton('image-upload')}>
                                <EditIcon className="image-edit-icon" />
                                <img src={image} alt="Perk" style={{width: '100%'}} />
                                <input id="image-upload" type="file" accept="image/*" aria-label="image" onChange={this.handleImageChange('image')} style={{visibility: 'hidden', position: 'absolute'}} />
                            </div>
                            <Typography component="div" className={classnames(classes.center, classes.imageTitle, classes.editable)}>
                                <InputBase className="edit-text-field" onChange={this.handleChange('image_title')} style={{fontSize: '1em'}} inputProps={{ 'aria-label': 'naked', style: {textAlign: 'center'} }} value={image_title} />
                            </Typography>
                        </Grid>
                        <Grid item xs={9}>
                            <Typography 
                                component='div' 
                                className={ this.state['markdown-editor-desc'] ? classnames(classes.editable, classes.markdownFocused) : classnames(classes.editable, classes.markdown)} 
                                onClick={this.handleMarkdownClick('markdown-editor-desc')}
                            >
                                <InputBase 
                                    id="markdown-editor-desc"
                                    className="edit-text-field" 
                                    onChange={this.handleChange('long_desc')}
                                    style={{fontSize: '1em'}} 
                                    inputProps={{ 'aria-label': 'naked', type: 'hidden', onBlur: this.handleMarkdownFocusOut('markdown-editor-desc') }} 
                                    value={long_desc} 
                                    multiline
                                />
                                <Markdown className="markdown-content" source={long_desc} />
                            </Typography>
                            <Grid container direction="row" justify="space-around">
                                <Grid item>
                                    <Grid container direction="row" spacing={1} alignItems="center" className={classes.hintItem}>
                                        <Grid item><img src={refundIcon} alt="refund" style={{width: '100%'}} /></Grid>
                                        <Grid item><Typography>100% Refund before Launch</Typography></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1} alignItems="center" className={classes.hintItem}>
                                        <Grid item><img src={secureIcon} alt="safe" style={{width: '100%'}} /></Grid>
                                        <Grid item><Typography>{'Safe & Secure Payment'}</Typography></Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <Grid container direction="row" spacing={1} alignItems="center" className={classes.hintItem}>
                                        <Grid item><img src={processIcon} alt="process" style={{width: '100%'}} /></Grid>
                                        <Grid item><Typography>Transparent Process</Typography></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container direction="row" spacing={8}>
                        <Grid item xs={4}>
                            <Typography variant="h6" className={classes.sectionTitle}>Your Reservation</Typography>
                            <Grid container direction="row" spacing={1} justify="space-between" style={{marginBottom: '10px'}}>
                                <Grid item xs={9}>
                                    <Typography component="div" className={classes.editable} >
                                        <InputBase className="edit-text-field" onChange={this.handleChange('short_desc')} style={{fontSize: '1em'}} inputProps={{'aria-label': 'naked'}} value={short_desc} />
                                    </Typography>
                                </Grid>
                                <Grid item xs={3} className={classes.priceHint}>
                                    <Typography className="price-hint" style={{fontWeight: 500}}>cent</Typography>
                                    <Typography component="div" className={classes.editable} style={{fontWeight: 500}}>
                                        <InputBase className="edit-text-field" onChange={this.handleChange('amount')} style={{fontSize: '1em'}} inputProps={{'aria-label': 'naked', style:{textAlign: 'right'}}} value={amount} />
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container direction="row" justify="space-between">
                                <Typography style={{fontWeight: 500}}>Total</Typography>
                                <Typography style={{fontWeight: 500}}>$ {amount / 100}</Typography>
                            </Grid>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="h6" className={classes.sectionTitle}>Payment Information</Typography>
                            <Payment item={this.props.item} primary={primary} secondary={secondary} />
                        </Grid>
                    </Grid>
                </Container>
                <Line style={{marginTop: 50, marginBottom: 50}} />
                <Container maxWidth="md">
                    <Grid container direction="column" alignItems="center" spacing={5} style={{marginTop: '15px'}}>
                        <Grid item style={{width: '100%'}}>
                            <div className={classes.imageButton} style={{width: '100%'}} onClick={this.handleImageButton('s-image-upload')}>
                                <EditIcon className="image-edit-icon" />
                                <img src={s_image} alt="Perk" style={{width: '100%'}} />
                                <input id="s-image-upload" type="file" accept="image/*" aria-label="image" onChange={this.handleImageChange('s_image')} style={{visibility: 'hidden', position: 'absolute'}} />
                            </div>
                        </Grid>
                        <Grid item style={{width: '100%'}}>
                            <Typography variant="h4" className={classnames(classes.center, classes.header, classes.sectionMargin, classes.editable)}>
                                <InputBase className="edit-text-field" onChange={this.handleChange('s_title')} style={{fontSize: '1em'}} inputProps={{ 'aria-label': 'naked', style: {textAlign: 'center'} }} value={s_title} />
                            </Typography>
                        </Grid>
                        <Grid item style={{width: '100%'}}>
                            <Typography 
                                component='div' 
                                className={ this.state['markdown-editor-s-desc'] ? classnames(classes.editable, classes.markdownFocused) : classnames(classes.editable, classes.markdown)} 
                                onClick={this.handleMarkdownClick('markdown-editor-s-desc')}
                            >
                                <InputBase 
                                    id="markdown-editor-s-desc"
                                    className="edit-text-field" 
                                    onChange={this.handleChange('s_desc')}
                                    style={{fontSize: '1em'}} 
                                    inputProps={{ 'aria-label': 'naked', type: 'hidden', onBlur: this.handleMarkdownFocusOut('markdown-editor-s-desc') }} 
                                    value={s_desc} 
                                    multiline
                                />
                                <Markdown className="markdown-content" source={s_desc} />
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography variant="caption" style={{color: 'gray'}}>Button Title (button will be hidden if keep blank)</Typography>
                            <Typography component="div" style={{minWidth: '300px', minHeight: '50px'}} className={classes.editable}>
                                <InputBase 
                                    className="edit-text-field" 
                                    onChange={this.handleChange('s_button_title')} 
                                    style={{fontSize: '1em'}} 
                                    inputProps={{ 'aria-label': 'naked', style: {textAlign: 'center'} }} 
                                    value={s_button_title ? s_button_title : ''} 
                                />
                            </Typography>
                        </Grid>
                        <Grid item style={{width: '100%'}}>
                            <Typography variant="caption" style={{color: 'gray'}}>Button Link (button will be hidden if keep blank)</Typography>
                            <Typography component="div" className={classes.editable}>
                                <InputBase 
                                    className="edit-text-field" 
                                    onChange={this.handleChange('s_button_href')} 
                                    style={{fontSize: '1em'}} 
                                    inputProps={{ 'aria-label': 'naked', style: {textAlign: 'center'} }} 
                                    value={s_button_href ? s_button_href : ''} 
                                />
                            </Typography>
                        </Grid>
                    </Grid>
                </Container>
            </MuiThemeProvider>
        )
    }
}


export const ItemPreview = withSnackbar(withStyles(style)(P))
