import axios from 'axios'


class Networking {
    base =  axios.create({
        // baseURL: 'http://localhost:8000/',
        baseURL: 'https://scripts.vinyl2.com/',
        timeout: 600000,
        headers: {'Content-Type': 'application/json'}
    })

    constructor() {
        const token = localStorage.getItem('token')
        if (token) {
            this.base.defaults.headers['Authorization'] = 'Token ' + token
        }
    }

    isAuthenticated() {
        if (localStorage.getItem('token')) {
            return true
        }
        return false
    }

    signOut() {
        localStorage.removeItem('token')
    }

    async authenticate(username, password) {
        const r = await this.base.post('api-token-auth/', { username, password })
        localStorage.setItem('token', r.data.token)
        this.base.defaults.headers['Authorization'] = 'Token ' + r.data.token
        return r
    }

    async fetchIGGPerkList(website) {
        const r = await this.base.get('script/perk/?website=' + website)
        return r.data
    }

    async addPerk(perk) {
        const r= await this.base.post('script/perk/', perk)
        return r.data
    }

    async patchPerk(perk) {
        const r = await this.base.patch('script/perk/', perk)
        return r.data
    }

    async deletePerk(id) {
        const r = await this.base.delete('script/perk/?id=' + id)
        return r.data
    }

    async fetchCampaignList() {
        const r = await this.base.get('script/campaign/')
        return r.data
    }

    async addCampaign(campaign) {
        const r= await this.base.post('script/campaign/', campaign)
        return r.data
    }

    async patchCampaign(campaign) {
        const r = await this.base.patch('script/campaign/', campaign)
        return r.data
    }

    async deleteCampaign(id) {
        const r = await this.base.delete('script/campaign/?id=' + id)
        return r.data
    }

    async fetchPayableItemList() {
        const r = await this.base.get('payment/items/')
        return r.data
    }

    async createOrPatchItem(itemID, data) {
        if (itemID) {
            return await this.patchItem(itemID, data)
        }
        const r = await this.base.post('payment/item/', data)
        return r.data
    }

    async patchItem(itemID, data) {
        const r = await this.base.patch('payment/item/patch/' + itemID + '/', data)
        return r.data
    }

    async uploadPerkImage(itemID, image) {
        let formData = new FormData()
        formData.append('image', image)
        const r = await this.base.patch('payment/item/patch/' + itemID + '/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return r.data
    }

    async uploadSuccessImage(itemID, image) {
        let formData = new FormData()
        formData.append('s_image', image)
        const r = await this.base.patch('payment/item/patch/' + itemID + '/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        return r.data
    }

    async deleteItem(itemID) {
        const r = await this.base.delete('payment/item/delete/' + itemID + '/')
        return r.data
    }

    async fetchOrderList() {
        const r = await this.base.get('payment/orders/')
        return r.data
    }

    async patchOrder(data) {
        const r = await this.base.patch('payment/order/', data)
        return r.data
    }

    async fetchPublicImages() {
        const r = await this.base.get('images/')
        return r.data
    }

    async uploadPublicImage(image, note, onProgress) {
        let formData = new FormData()
        formData.append('path', image)
        formData.append('note', note)

        const r = await this.base.post('images/upload/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: onProgress
        })
        return r.data
    }

    async deletePublicImage(id) {
        const r = await this.base.delete('images/delete/' + id + '/')
        return r.data
    }
}

export default new Networking()