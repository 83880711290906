import { TableCell, withStyles } from '@material-ui/core'


export const StyledTableCell = withStyles(theme => ({
    head: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.primary.main,
    },
    body: {
        fontSize: 14,
    },
}))(TableCell)