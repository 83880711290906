import React, { Component } from 'react'
import { withStyles, TableCell, TableRow, IconButton, Button} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { withSnackbar } from 'notistack'


const styles = theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    tip: {
        fontSize: '10px',
    },
    listItem: {
        fontSize: '15px',
        minHeight: '15px',
    },
})


class ImageRow extends Component {
    state = {
        popAnchorEl: null,
        menuAnchorEl: null,
    }

    handleClick = () => {
        const {path, note} = this.props.image
        const result = '<a href="' + path + '"><img src="' + path + '" alt="' + note + '" /></a>'
        navigator.clipboard.writeText(result).then(()=> {
            this.props.enqueueSnackbar('Copied to Clipboard', {
                variant: 'success',
            })
        }, () => {
            this.props.enqueueSnackbar('Failed to Clipboard', {
                variant: 'error',
            })
        })
    }
    
    render() {
        const { image, onDelete } = this.props

        return (
            <TableRow key={image.id}>
                <TableCell>{image.id}</TableCell>
                <TableCell><Button onClick={this.handleClick}><img src={image.path} alt={image.note} height="100"/></Button></TableCell>
                <TableCell>{image.note}</TableCell>
                <TableCell>
                    <IconButton onClick={onDelete(image)}><DeleteIcon color="error" /></IconButton>
                </TableCell>
            </TableRow>
        )
    }
}


export default withSnackbar(withStyles(styles)(ImageRow))

