import React, { Component } from 'react'
import { withStyles, TableCell, TableRow, Typography, Popover, Checkbox, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import { CommonAlert } from 'Utils'
import { networking } from 'Models'


const styles = theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    tip: {
        fontSize: '10px',
    },
    listItem: {
        fontSize: '15px',
        minHeight: '15px',
    },
})


class R extends Component {
    state = {
        alert: false,
        popAnchorEl: null,
        refund: false,
        commentDialog: false,
        comment: '',
    }

    constructor(props) {
        super(props)
        this.state.refund = props.order.refund_mark
        this.state.orderDesc = props.order.desc
    }

    handlePopoverOpen = event => this.setState({popAnchorEl: event.currentTarget})
    handlePopoverClose = () => this.setState({popAnchorEl: null})
    handleAlertOpen = () => this.setState({alert: true})
    handleAlertClose = () => this.setState({alert: false})
    handleCommentChange = event => this.setState({comment: event.target.value})
    handleCommentDialogOpen = () => this.setState({commentDialog: true, comment: this.state.orderDesc ? this.state.orderDesc : ''})
    handleCommentDialogClose = () => this.setState({commentDialog: false})

    handleRefund = refund => () => {
        networking.patchOrder({
            id: this.props.order.id,
            refund_mark: refund,
        }).then(() => {
            this.setState({refund})
            if (!refund) {
                this.handleAlertClose()
            }
        })
    }

    handleCommentSave = () => {
        var comment = this.state.comment
        if (this.state.comment.length <= 0) {
            comment = null
        }
        networking.patchOrder({
            id: this.props.order.id,
            desc: comment,
        }).then(() => {
            this.setState({orderDesc: comment, commentDialog: false})
        })
    }
    
    render() {
        const { classes, order } = this.props
        const { popAnchorEl, commentDialog, orderDesc, comment } = this.state

        var desc = <Typography onClick={this.handleCommentDialogOpen}>{orderDesc}</Typography>
        if (!orderDesc) {
            desc = <Button color="primary" onClick={this.handleCommentDialogOpen}>Add Comment</Button>
        }

        const date= new Date(order.date_created)
        const date_created = '' + date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) +
            ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2)

        return (
            <TableRow key={order.id}>
                <TableCell>{order.id}</TableCell>
                <TableCell>
                    <Typography
                        aria-owns={'igg-order-refer-' + order.id}
                        aria-haspopup="true"
                        onMouseEnter={this.handlePopoverOpen}
                        onMouseLeave={this.handlePopoverClose}
                    >
                        {order.item.short_desc}
                    </Typography>
                    <Popover 
                        id={'igg-order-refer-' + order.id}
                        className={classes.popover}
                        classes={{
                            paper: classes.paper,
                        }}
                        open={popAnchorEl != null}
                        anchorEl={popAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={this.handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Typography className={classes.tip}>ID: {order.item.id}</Typography>
                        <Typography className={classes.tip}>Title: {order.item.title}</Typography>
                        <Typography className={classes.tip}>Amount: $ {order.item.amount / 100}</Typography>
                        <Typography className={classes.tip}>Active: {order.item.active ? 'Yes' : 'No'}</Typography>
                    </Popover>
                </TableCell>
                <TableCell>$ {order.amount / 100}</TableCell>
                <TableCell>{order.name}</TableCell>
                <TableCell>{order.email}</TableCell>
                <TableCell>{order.status}</TableCell>
                <TableCell>{date_created}</TableCell>
                <TableCell><Checkbox checked={this.state.refund} onChange={this.state.refund ? this.handleAlertOpen : this.handleRefund(true)} color="primary" /></TableCell>
                <TableCell>{desc}</TableCell>
                <CommonAlert 
                    title="Refund"
                    content="The order has been marked refunded, are you sure the refund are cancelled?"
                    left="Refund Withdrawn"
                    right="Cancel"
                    open={this.state.alert}
                    onClose={this.handleAlertClose}
                    onLeft={this.handleRefund(false)}
                    onRight={this.handleAlertClose}
                />
                <Dialog open={commentDialog} aria-labelledby="form-dialog-title" fullWidth>
                    <DialogTitle id="form-dialog-title">Order Comment</DialogTitle>
                    <DialogContent>
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} id="comment" value={comment} onChange={this.handleCommentChange} multiline fullWidth />
                    </DialogContent>
                    <DialogActions style={{padding: '15px'}}>
                        <Button onClick={this.handleCommentDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={this.handleCommentSave} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
            </TableRow>
        )
    }
}


export const OrderRow = withStyles(styles)(R)
