import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import { Container, AppBar, Toolbar, IconButton, Typography, Button } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import MenuIcon from '@material-ui/icons/Menu'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import { Login } from './Login'
import { ControlPanel } from './Panel'
import { IGGScripts } from './IGGScripts'
import { PublicImages } from './PublicImages'
import { KSScripts, KSMessenger } from './KSScripts'
import { ItemList, OrderList } from './Reservation'
import { CommonAlert } from './Utils'
import { networking } from './Models'

const theme = createMuiTheme({
    typography: {
        useNextVariants: true,
        fontFamily: [
            'Avenir Next', 
            '-apple-system', 
            'BlinkMacSystemFont', 
            'Segoe UI', 'Roboto', 'Oxygen',
            'Ubuntu', 'Cantarell', 'Fira Sans', 
            'Droid Sans', 'Helvetica Neue',
        ]
    },
    palette: {
        primary: {
            main: '#F8B932',
        },
        secondary:  {
            main: '#191A1A',
        }
    }
})


class App extends Component {
    state = {
        isAuthenticated: false,
        alert: false,
    }

    componentDidMount() {
        this.setState({isAuthenticated: networking.isAuthenticated()})
    }

    handleLogin = () => {
        this.setState({isAuthenticated: networking.isAuthenticated()})
    }

    handleSignOut = () => {
        networking.signOut()
        this.setState({isAuthenticated: networking.isAuthenticated(), alert: false})
    }

    handleAlertOpen = () => {
        this.setState({alert: true})
    }

    handleAlertClose = () => {
        this.setState({alert: false})
    }

    render() {
        return (
            <MuiThemeProvider theme={theme}>
                <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                    <Container maxWidth="xl">
                        {(() => {
                            if (this.state.isAuthenticated) {
                                return (
                                    <Router>
                                        <AppBar position="fixed">
                                            <Toolbar>
                                                <IconButton edge="start" color="inherit" aria-label="Menu">
                                                    <MenuIcon />
                                                </IconButton>
                                                <Typography variant="h6" style={{flexGrow: 1}}>
                                                    <Link to="/" style={{textDecoration: 'none', color: 'black', marginLeft: 15}}>Vinyl Growth Management</Link>
                                                </Typography>
                                                <Button color="inherit" onClick={this.handleAlertOpen}>Sign Out</Button>
                                            </Toolbar>
                                        </AppBar>
                                        <Route path="/" exact component={ControlPanel} />
                                        <Route path="/scripts/igg/" component={IGGScripts} />
                                        <Route path="/scripts/ks/" component={KSScripts} />
                                        <Route path="/scripts/ksmessenger/" component={KSMessenger} />
                                        <Route path="/reservations/item/" component={ItemList} />
                                        <Route path="/reservations/orders/" component={OrderList} />
                                        <Route path="/public/images/" component={PublicImages} />
                                    </Router>
                                )
                            }
                            return (<Login onLogin={this.handleLogin} />)
                        })()}
                        <CommonAlert 
                            open={this.state.alert} 
                            onClose={this.handleAlertClose} 
                            title="Sign Out" 
                            content="Are you sure to sign out?"
                            left="Sign Out"
                            right="cancel"
                            onLeft={this.handleSignOut}
                            onRight={this.handleAlertClose}
                        />
                    </Container>
                </SnackbarProvider>
            </MuiThemeProvider>
        )
    }
}

export default App
