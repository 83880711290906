import React, { Component } from 'react'
import { Card, CardMedia, CardContent, CardActionArea, Typography, Grid, withStyles } from '@material-ui/core'
import { FixedSpace, Line } from 'Utils'
import iggPic from './igg.png'
import ksPic from './ks.png'
import ordersPic from './orders.jpeg'
import reservationPic from './reservation.jpeg'
import imagesPic from './images.jpg'

const style = {
    card: {
        width: 345,
    },
    media: {
        height: 150,
    },
    sectionTitle: {
        fontSize: '1em',
        fontWeight: 'bold',
    }
}


class Panel extends Component {
    handleModuleEnter = url => () => {
        this.props.history.push(url)
    }

    render() {
        const { classes } = this.props

        return (
            <Grid container direction="column" spacing={3}>
                <Grid item><FixedSpace size="xl2" /></Grid>
                <Grid item>
                    <Typography variant="overline" className={classes.sectionTitle} gutterBottom>Scripts</Typography>
                    <Line />
                </Grid>
                <Grid item>
                    <Grid container spacing={5}>
                        <Grid item>
                            <Card className={classes.card}>
                                <CardActionArea onClick={this.handleModuleEnter('scripts/igg/')}>
                                    <CardMedia
                                        className={classes.media}
                                        image={iggPic}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">IGG Perks Top-up</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card className={classes.card}>
                                <CardActionArea onClick={this.handleModuleEnter('scripts/ks/')}>
                                    <CardMedia
                                        className={classes.media}
                                        image={ksPic}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">KS Rewards Top-up</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card className={classes.card}>
                                <CardActionArea onClick={this.handleModuleEnter('scripts/ksmessenger/')}>
                                    <CardMedia
                                        className={classes.media}
                                        image={ksPic}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">KS Backer Auto Messenger</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item><FixedSpace size="md" /></Grid>
                <Grid item>
                    <Typography variant="overline" className={classes.sectionTitle} gutterBottom>Payment</Typography>
                    <Line />
                </Grid>
                <Grid item>
                    <Grid container spacing={5}>
                        <Grid item>
                            <Card className={classes.card}>
                                <CardActionArea onClick={this.handleModuleEnter('reservations/item/list/')}>
                                    <CardMedia
                                        className={classes.media}
                                        image={reservationPic}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">Reservation Items</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                        <Grid item>
                            <Card className={classes.card}>
                                <CardActionArea onClick={this.handleModuleEnter('reservations/orders/')}>
                                    <CardMedia
                                        className={classes.media}
                                        image={ordersPic}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">Reservation Orders</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item><FixedSpace size="md" /></Grid>
                <Grid item>
                    <Typography variant="overline" className={classes.sectionTitle} gutterBottom>Utils</Typography>
                    <Line />
                </Grid>
                <Grid item>
                    <Grid container spacing={5}>
                        <Grid item>
                            <Card className={classes.card}>
                                <CardActionArea onClick={this.handleModuleEnter('public/images/')}>
                                    <CardMedia
                                        className={classes.media}
                                        image={imagesPic}
                                    />
                                    <CardContent>
                                        <Typography variant="h6">Public Images</Typography>
                                    </CardContent>
                                </CardActionArea>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}


export const ControlPanel = withStyles(style)(Panel)
