import React, { Component } from 'react'
import { Grid, Table, TableBody, TableHead, TableRow, Paper, withStyles, IconButton, Typography } from '@material-ui/core'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import { FixedSpace, CommonAlert, StyledTableCell } from 'Utils'
import CampaignRow from './CampaignRow'
import { networking } from 'Models'


const style = theme => ({
    textField: {
        marginBottom: theme.spacing(1)
    },
    menu: {
        width: 200,
    },
})

const defaults = {
    editingContent: () => ({
        campaign_id: '',
        username: '',
        password: '',
        frequency: 3,
        switch: 'off',
        message: '',
    })
}

class KSMessenger extends Component {
    state = {
        campaigns: [],
        editingDialog: false,
        editingContent: defaults.editingContent(),
        deletingCampaign: null,
        alert: false,
    }

    componentDidMount() {
        this.refresh()
    }

    refresh = () => {
        networking.fetchCampaignList().then(campaigns => {
            this.setState({campaigns})
        })
    }

    handleEditingDialogOpen = content => () => {
        this.setState({editingDialog: true, editingContent: content})
    }

    handleEditingDialogClose = () => {
        this.setState({editingDialog: false, editingContent: defaults.editingContent()})
    }

    handleEditing = event => {
        this.setState({editingContent: {
            ...this.state.editingContent,
            [event.target.id]: event.target.value,
        }})
    }

    handleSaving = () => {
        const campaign = this.state.editingContent
        if (campaign.switch) {
            campaign.switch = campaign.switch === 'on'
        }
        if (campaign.id) {
            networking.patchCampaign(campaign).then(() => {
                this.refresh()
            })
        } else {
            networking.addCampaign(campaign).then(() => {
                this.refresh()
            })
        }
        this.handleEditingDialogClose()
    }

    handleEditCampaign = campaign => {
        var d = defaults.editingContent()
        d.id = campaign.id
        d.campaign_id = campaign.campaign_id
        d.username = campaign.username
        d.password = campaign.password
        d.frequency = campaign.frequency
        d.switch = campaign.switch ? 'on' : 'off'
        d.message = campaign.message
        this.setState({editingDialog: true, editingContent: d})
    }

    handleDeleteCampaign = () => {
        networking.deleteCampaign(this.state.deletingCampaign.id).then(() => {
            this.refresh()
        }).catch(e => {
            console.log(e)
        })
        this.handleAlertClose()
    }

    handleAlertOpen = campaign => {
        this.setState({alert: true, deletingCampaign: campaign})
    }

    handleAlertClose = () => {
        this.setState({alert: false})
    }

    render() {
        const { classes } = this.props
        const { editingDialog, editingContent } = this.state
        return (
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item><FixedSpace size="xl3" /></Grid>
                <Grid item>
                    <Typography variant="h4">KS Backer Auto Messenger</Typography>
                </Grid>
                <Grid item><FixedSpace size="md" /></Grid>
                <Grid item style={{width: '100%', textAlign: 'right'}}>
                    <IconButton onClick={this.refresh}><RefreshIcon color="primary" /></IconButton>
                    <IconButton onClick={this.handleEditingDialogOpen(defaults.editingContent())}><AddIcon color="primary" /></IconButton>
                </Grid>
                <Grid item style={{width: '100%'}}>
                    <Paper style={{overflow: 'hidden'}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Project ID</StyledTableCell>
                                    <StyledTableCell>Username</StyledTableCell>
                                    <StyledTableCell>Frequecy (min)</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell>Switch</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.campaigns.map(campaign => {
                                    return (<CampaignRow campaign={campaign} key={campaign.id} onEdit={this.handleEditCampaign} onDelete={this.handleAlertOpen} />)
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <CommonAlert 
                    title="Delete Campaign"
                    content="This action CANNOT be restored! Are you sure to delete this campaign?"
                    left="Delete"
                    right="Cancel"
                    open={this.state.alert}
                    onClose={this.handleAlertClose}
                    onLeft={this.handleDeleteCampaign}
                    onRight={this.handleAlertClose}
                />
                <Dialog open={editingDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Auto Messenger Parameter</DialogTitle>
                    <DialogContent>
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="campaign_id" label="Project ID *" value={editingContent.campaign_id} onChange={this.handleEditing} fullWidth />
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="username" label="Username *" value={editingContent.username} onChange={this.handleEditing} fullWidth />
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="password"label="Password *" value={editingContent.password} onChange={this.handleEditing} fullWidth />
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="frequency" label="Frequency *" type="number" step="1" value={editingContent.frequency} onChange={this.handleEditing} fullWidth />
                        <TextField 
                            variant="outlined" 
                            margin="dense" 
                            id="switch"
                            label="Switch"  
                            select
                            InputLabelProps={{shrink: true}} 
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            value={editingContent.switch} 
                            onChange={this.handleEditing} 
                            fullWidth
                        >
                            <option value="off">Off</option>
                            <option value="on">On</option>
                        </TextField>
                        <TextField variant="outlined" InputLabelProps={{shrink: true}}  margin="dense" id="message" label="Message" value={editingContent.message} onChange={this.handleEditing} fullWidth multiline/>
                    </DialogContent>
                    <DialogActions style={{padding: '15px'}}>
                        <Button onClick={this.handleEditingDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={this.handleSaving} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid item><FixedSpace size="xl2" /></Grid>
            </Grid>
        )
    }
}

export default withStyles(style)(KSMessenger)
