import React, { Component } from 'react'
import { Button, Typography, Grid, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText} from '@material-ui/core'
import { Elements, StripeProvider, CardElement, injectStripe } from 'react-stripe-elements'


class F extends Component {
    state = {
        dialog: false,
        dialogContentText: '',
        name: '',
        nameErrorMessage: '',
        email: '',
        emailErrorMessage: '',
    }

    handleInfoChange = key => event => {
        this.setState({[key]: event.target.value, [key+'ErrorMessage']: ''})
    }
 
    handleDialogClose = () => {
        this.setState({dialog: false})
    }

    validate() {
        var result = true
        const {name, email} = this.state
        if (name.trim().length <= 0) {
            this.setState({nameErrorMessage: 'name cannot be blank'})
            result = false
        }

        const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i
        if (!re.test(email)) {
            this.setState({emailErrorMessage: 'not an valid email'})
            result = false
        }
        return result
    }

    submit = async () => {
        if (!this.validate()) {
            return
        }
    }
    
    render() {
        return (
            <Grid container direction="column">
                <Grid item>
                    <Grid container direction="row" spacing={6}>
                        <Grid item xs={4}>
                            <Typography>Name</Typography>
                            <input className="StripeElement" name="name" placeholder="John Smith" onChange={this.handleInfoChange('name')} />
                            <Typography>{this.state.nameErrorMessage}</Typography>
                        </Grid>
                        <Grid item xs={4}>
                            <Typography>Email</Typography>
                            <input className="StripeElement" name="email" placeholder="john@example.com" onChange={this.handleInfoChange('email')} />
                            <Typography>{this.state.emailErrorMessage}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item>
                    <Typography>Card Detail</Typography>
                    <Grid item xs={10}>
                        <CardElement />
                    </Grid>
                </Grid>
                <Grid item xs={4}>
                    <Button variant="contained" color="primary" onClick={this.submit} fullWidth>Complete Reservation</Button>
                </Grid>

                <Dialog open={this.state.dialog} onClose={this.handleDialogClose} fullWidth>
                    <DialogTitle>Error</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{this.state.dialogContentText}</DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="primary" onClick={this.handleDialogClose} style={{margin: '10px'}}>OK</Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        )
    }
}

const PaymentForm = injectStripe(F)


export class Payment extends Component {
    state = {
        stripe: null
    }

    componentDidMount() {
        const script = document.createElement('script')
        script.onload = () => {
            this.setState({stripe: window.Stripe('pk_test_Lz88muwfBiHD9bCcAuT5bFYA00o3IOc3Rb')})
        }
        script.src = 'https://js.stripe.com/v3/'

        document.head.appendChild(script)
    }

    render() {
        

        return (
            <StripeProvider stripe={this.state.stripe}>
                <Elements>
                    <PaymentForm />
                </Elements>
            </StripeProvider>
        )
    }
}