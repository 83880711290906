import React, { Component } from 'react'
import { Grid, Button, TextField, Card, CardContent, CardHeader } from '@material-ui/core'
import { FixedSpace } from 'Utils'

import { networking } from 'Models'


class Login extends Component {
    state = {
        username: null,
        password: null,
    }

    handleAnthenticate = () => {
        networking.authenticate(this.state.username, this.state.password).then(() => {
            this.props.onLogin()
        })
    }

    handleInput = key => event => {
        this.setState({[key]: event.target.value})
    }

    render() {
        return (
            <Grid 
                container
                direction="column"
                justify="center"
                alignItems="center"
            >
                <Grid item style={{'width': '50%'}}>
                    <FixedSpace size="xl2" />
                    <Card>
                        <CardHeader title="Vinyl Scripts Management" subheader="v0.1" style={{'textAlign': 'center'}}/>
                        <CardContent>
                            <TextField variant="outlined" label="Username" margin="dense" onChange={this.handleInput('username')} fullWidth />
                            <FixedSpace size="xs4" />
                            <TextField variant="outlined" label="Password" type="password" onChange={this.handleInput('password')} margin="dense" fullWidth />
                            <FixedSpace size="sm" />
                            <Button variant="contained" color="primary" onClick={this.handleAnthenticate} fullWidth>Login</Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        )
    }
}


export default Login
