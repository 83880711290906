import React, { Component } from 'react'
import { Grid, Table, TableBody, TableHead, TableRow, Paper, withStyles, IconButton, Typography } from '@material-ui/core'
import { SnackbarProvider } from 'notistack'
import { BrowserRouter as Router, Route, Redirect } from 'react-router-dom'
import { FixedSpace, StyledTableCell, CommonAlert } from 'Utils'
import AddIcon from '@material-ui/icons/Add'
import { networking } from 'Models'
import { ItemRow } from './ItemRow'
import { ItemPreview } from './Preview'

import addPictureIcon from './Preview/add_picture.svg'


const style = {

}

export class I extends Component {
    state = {
        items: [],
        alert: false,
        deletingItem: null,
    }

    componentDidMount() {
        this.refresh()
    }

    refresh = () => {
        networking.fetchPayableItemList().then(response => {
            this.setState({items: response})
        })
    }

    handleAdd = () => {
        this.props.onEdit({
            id: null,
            title: 'Enter Title Here',
            image: addPictureIcon,
            image_title: 'Enter Image Title Here',
            short_desc: 'Enter Short Desc Here',
            long_desc: 'Enter Long Description Here with **Markdown**',
            primary_color: '#F8B932',
            secondary_color: '#191A1A',
            font: 'Avenir Next',
            amount: 100,
            s_image: addPictureIcon,
            s_title: 'Enter Success Title Here',
            s_desc: 'Enter Success Description Here',
            s_button_title: 'Button Title Here',
            s_button_href: 'https://google.com/'
        })
        this.props.history.push('/reservations/item/preview/')
    }

    handleEditing = item => () => {
        this.props.onEdit(item)
        this.props.history.push('/reservations/item/preview/')
    }

    handleAlertOpen = item => () => {
        this.setState({alert: true, deletingItem: item})
    }

    handleAlertClose = () => {
        this.setState({alert: false, deletingItem: null})
    }

    handleDeleteItem = () => {
        networking.deleteItem(this.state.deletingItem.id).then(() => {
            this.refresh()
        })
        this.setState({alert: false, deletingItem: null})
    }

    render() {
        if (this.props.redirect === true) {
            return (
                <Redirect push to="/reservations/item/preview/" />
            )
        }

        return (
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item><FixedSpace size="xl3" /></Grid>
                <Grid item>
                    <Typography variant="h4">Reservation Items</Typography>
                </Grid>
                <Grid item><FixedSpace size="md" /></Grid>
                <Grid item style={{width: '100%', textAlign: 'right'}}>
                    <IconButton onClick={this.handleAdd}><AddIcon color="primary" /></IconButton>
                </Grid>
                <Grid item style={{width: '100%'}}>
                    <Paper style={{overflow: 'hidden'}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Item ID</StyledTableCell>
                                    <StyledTableCell>Title</StyledTableCell>
                                    <StyledTableCell>Short Desc</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Active</StyledTableCell>
                                    <StyledTableCell>Actions</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.items.map(item => {
                                    return (<ItemRow key={item.id} item={item} onEdit={this.handleEditing(item)} onDelete={this.handleAlertOpen(item)}/>)
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <CommonAlert 
                    title="Delete Item"
                    content="This action CANNOT be restored! Are you sure to delete this item?"
                    left="Delete"
                    right="Cancel"
                    open={this.state.alert}
                    onClose={this.handleAlertClose}
                    onLeft={this.handleDeleteItem}
                    onRight={this.handleAlertClose}
                />
            </Grid>
        )
    }
}


const Items = withStyles(style)(I)

export class ItemList extends Component {
    state = {
        editingItem: null,
    }

    handleEditing = item => {
        this.setState({editingItem: item})
    }

    render() {
        const { match } = this.props
        return (
            <Router>
                <Route 
                    path={match.url + '/preview/'} 
                    render={props => 
                        <SnackbarProvider maxSnack={3} anchorOrigin={{vertical: 'top', horizontal: 'right'}}>
                            <ItemPreview {...props} item={this.state.editingItem} />
                        </SnackbarProvider>
                    } 
                />
                <Route path={match.url + '/list/'} render={props => <Items {...props} onEdit={this.handleEditing} />} />
            </Router>
        )
    }
}
