import React, { Component } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core'

export class CommonAlert extends Component {
    render() {
        const { open, onClose, title, content, left, right, onLeft, onRight } = this.props 

        return (
            <Dialog
                open={open}
                onClose={onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth
            >
                <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {content}
                    </DialogContentText>
                </DialogContent>
                <DialogActions style={{padding: '15px'}}>
                    <Button onClick={onLeft} color="primary">
                        {left}
                    </Button>
                    <Button variant="contained" onClick={onRight} color="primary" autoFocus>
                        {right}
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}
