import React, { Component } from 'react'
import { Grid, Table, TableBody, TableHead, TableRow, Paper, withStyles, IconButton, Typography } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { FixedSpace, StyledTableCell } from 'Utils'
import { OrderRow } from './OrderRow'
import { networking } from 'Models'


const style = theme => ({
    textField: {
        marginBottom: theme.spacing(1)
    },
    menu: {
        width: 200,
    },
})

class O extends Component {
    state = {
        orders: [],
        alert: false,
        refundOrder: null,
    }

    componentDidMount() {
        this.refresh()
    }

    refresh = () => {
        networking.fetchOrderList().then(response => {
            this.setState({orders: response})
        })
    }

    render() {
        return (
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item><FixedSpace size="xl3" /></Grid>
                <Grid item>
                    <Typography variant="h4">Reservation Orders</Typography>
                </Grid>
                <Grid item><FixedSpace size="md" /></Grid>
                <Grid item style={{width: '100%', textAlign: 'right'}}>
                    <IconButton onClick={this.refresh}><RefreshIcon color="primary" /></IconButton>
                </Grid>
                <Grid item style={{width: '100%'}}>
                    <Paper style={{overflow: 'hidden'}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Order ID</StyledTableCell>
                                    <StyledTableCell>Reservation</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Customer Name</StyledTableCell>
                                    <StyledTableCell>Customer Email</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell>Date</StyledTableCell>
                                    <StyledTableCell>Refund Manually?</StyledTableCell>
                                    <StyledTableCell>Comments</StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.orders.map(order => {
                                    return (<OrderRow order={order} key={order.id} />)
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <Grid item><FixedSpace size="xl2" /></Grid>
            </Grid>
        )
    }
}

export const OrderList = withStyles(style)(O)
