import React, { Component } from 'react'
import { withStyles, TableCell, TableRow, Switch, IconButton} from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'
import LinkIcon from '@material-ui/icons/Link'
import { withSnackbar } from 'notistack'
import { networking } from 'Models'


const styles = theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    tip: {
        fontSize: '10px',
    },
    listItem: {
        fontSize: '15px',
        minHeight: '15px',
    },
})


class I extends Component {
    state = {
        menuAnchorEl: null,
    }

    constructor(props) {
        super(props)

        this.state['active'] = this.props.item.active
    }

    handleMenuOpen = event => this.setState({menuAnchorEl: event.currentTarget})
    handleMenuClose = (callback=null, keepID=true) => () => {
        if (callback) {
            if (keepID) {
                callback(this.props.item)
            } else {
                callback({
                    ...this.props.item,
                    id: null,
                })
            }
        }
        this.setState({menuAnchorEl: null})
    }

    handleActiveChange = event => {
        let checked = event.target.checked
        this.setState({active: checked})
        networking.patchItem(this.props.item.id, {active: checked}).then(() => {
            this.props.enqueueSnackbar(checked ? 'Activated' : 'Deactivated', {
                variant: checked ? 'success' : 'warning',
            })
        })
    }

    handleLink = () => {
        // let win = window.open('http://localhost:3001/' + this.props.item.id, '_blank')
        let win = window.open('https://reservation.higizmos.com/' + this.props.item.id, '_blank')
        win.focus()
    }
    
    render() {
        const { item, onDelete, onEdit } = this.props

        return (
            <TableRow key={item.id}>
                <TableCell>{item.id}</TableCell>
                <TableCell>{item.title}</TableCell>
                <TableCell>{item.short_desc}</TableCell>
                <TableCell>{'$ ' + item.amount / 100}</TableCell>
                <TableCell><Switch color="primary" checked={this.state.active} onChange={this.handleActiveChange}/></TableCell>
                <TableCell>
                    <IconButton onClick={onEdit}><EditIcon /></IconButton>
                    <IconButton onClick={this.handleLink}><LinkIcon /></IconButton>
                    <IconButton onClick={onDelete}><DeleteIcon color="error" /></IconButton>
                </TableCell>
            </TableRow>
        )
    }
}


export const ItemRow = withSnackbar(withStyles(styles)(I))
