import React, { Component } from 'react'
import { Grid, Table, TableBody, TableHead, TableRow, Paper, withStyles, IconButton, Typography } from '@material-ui/core'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import { FixedSpace, CommonAlert, StyledTableCell } from 'Utils'
import PerkRow from './PerkRow'
import { networking } from 'Models'


const style = theme => ({
    textField: {
        marginBottom: theme.spacing(1)
    },
    menu: {
        width: 200,
    },
})

const defaults = {
    editingContent: () => ({
        campaign_id: '',
        perk_id: '',
        username: '',
        password: '',
        frequency: 5,
        threshold: 3,
        increment: 5,
        switch: 'off',
        note: '',
        website: 'ks',
    })
}

class KSScripts extends Component {
    state = {
        perks: [],
        editingDialog: false,
        editingContent: defaults.editingContent(),
        deletingPerk: null,
        alert: false,
    }

    componentDidMount() {
        this.refresh()
    }

    refresh = () => {
        networking.fetchIGGPerkList('ks').then(perks => {
            this.setState({perks})
        })
    }

    handleEditingDialogOpen = content => () => {
        this.setState({editingDialog: true, editingContent: content})
    }

    handleEditingDialogClose = () => {
        this.setState({editingDialog: false, editingContent: defaults.editingContent()})
    }

    handleEditing = event => {
        this.setState({editingContent: {
            ...this.state.editingContent,
            [event.target.id]: event.target.value,
        }})
    }

    handleSaving = () => {
        const perk = this.state.editingContent
        if (perk.switch) {
            perk.switch = perk.switch === 'on'
        }
        if (perk.id) {
            networking.patchPerk(perk).then(() => {
                this.refresh()
            })
        } else {
            networking.addPerk(perk).then(() => {
                this.refresh()
            })
        }
        this.handleEditingDialogClose()
    }

    handleEditPerk = perk => {
        var d = defaults.editingContent()
        d.id = perk.id
        d.campaign_id = perk.campaign_id
        d.perk_id = perk.perk_id
        d.username = perk.username
        d.password = perk.password
        d.threshold = perk.threshold
        d.increment = perk.increment
        d.frequency = perk.frequency
        d.switch = perk.switch ? 'on' : 'off'
        d.note = perk.note
        this.setState({editingDialog: true, editingContent: d})
    }

    handleDeletePerk = () => {
        networking.deletePerk(this.state.deletingPerk.id).then(() => {
            this.refresh()
        }).catch(e => {
            console.log(e)
        })
        this.handleAlertClose()
    }

    handleAlertOpen = perk => {
        this.setState({alert: true, deletingPerk: perk})
    }

    handleAlertClose = () => {
        this.setState({alert: false})
    }

    render() {
        const { classes } = this.props
        const { editingDialog, editingContent } = this.state
        return (
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item><FixedSpace size="xl3" /></Grid>
                <Grid item>
                    <Typography variant="h4">KS Rewards Top-up</Typography>
                </Grid>
                <Grid item><FixedSpace size="md" /></Grid>
                <Grid item style={{width: '100%', textAlign: 'right'}}>
                    <IconButton onClick={this.refresh}><RefreshIcon color="primary" /></IconButton>
                    <IconButton onClick={this.handleEditingDialogOpen(defaults.editingContent())}><AddIcon color="primary" /></IconButton>
                </Grid>
                <Grid item style={{width: '100%'}}>
                    <Paper style={{overflow: 'hidden'}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Reward ID</StyledTableCell>
                                    <StyledTableCell>Project Title</StyledTableCell>
                                    <StyledTableCell>Reward Title</StyledTableCell>
                                    <StyledTableCell>Amount</StyledTableCell>
                                    <StyledTableCell>Username</StyledTableCell>
                                    <StyledTableCell>Frequecy (min)</StyledTableCell>
                                    <StyledTableCell>Threshold</StyledTableCell>
                                    <StyledTableCell>Increment</StyledTableCell>
                                    <StyledTableCell>Status</StyledTableCell>
                                    <StyledTableCell>Switch</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.perks.map(perk => {
                                    return (<PerkRow perk={perk} key={perk.id} onEdit={this.handleEditPerk} onDuplicate={this.handleEditPerk} onDelete={this.handleAlertOpen} />)
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <CommonAlert 
                    title="Delete Perk"
                    content="This action CANNOT be restored! Are you sure to delete this perk?"
                    left="Delete"
                    right="Cancel"
                    open={this.state.alert}
                    onClose={this.handleAlertClose}
                    onLeft={this.handleDeletePerk}
                    onRight={this.handleAlertClose}
                />
                <Dialog open={editingDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Top-up Parameter</DialogTitle>
                    <DialogContent>
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="campaign_id" label="Project ID *" value={editingContent.campaign_id} onChange={this.handleEditing} fullWidth />
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="perk_id" label="Reward ID *" value={editingContent.perk_id} onChange={this.handleEditing} fullWidth />
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="username" label="Username *" value={editingContent.username} onChange={this.handleEditing} fullWidth />
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="password"label="Password *" value={editingContent.password} onChange={this.handleEditing} fullWidth type="password"/>
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="frequency" label="Frequency *" type="number" step="1" value={editingContent.frequency} onChange={this.handleEditing} fullWidth />
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="threshold" label="Threshold *" type="number" step="1" value={editingContent.threshold} onChange={this.handleEditing} fullWidth />
                        <TextField variant="outlined" InputLabelProps={{shrink: true}} margin="dense" id="increment" label="Increment *" type="number" step="1" value={editingContent.increment} onChange={this.handleEditing} fullWidth />
                        <TextField 
                            variant="outlined" 
                            margin="dense" 
                            id="switch"
                            label="Switch"  
                            select
                            InputLabelProps={{shrink: true}} 
                            SelectProps={{
                                native: true,
                                MenuProps: {
                                    className: classes.menu,
                                },
                            }}
                            value={editingContent.switch} 
                            onChange={this.handleEditing} 
                            fullWidth
                        >
                            <option value="off">Off</option>
                            <option value="on">On</option>
                        </TextField>
                        <TextField variant="outlined" InputLabelProps={{shrink: true}}  margin="dense" id="note" label="Note" value={editingContent.note} onChange={this.handleEditing} fullWidth />
                    </DialogContent>
                    <DialogActions style={{padding: '15px'}}>
                        <Button onClick={this.handleEditingDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={this.handleSaving} color="primary">
                            Save
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid item><FixedSpace size="xl2" /></Grid>
            </Grid>
        )
    }
}

export default withStyles(style)(KSScripts)
