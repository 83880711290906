import React, { Component } from 'react'
import { withStyles, TableCell, TableRow, Typography, Switch, IconButton, Popover, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core'
import MoreIcon from '@material-ui/icons/MoreHoriz'
import AddIcon from '@material-ui/icons/Add'
import EditIcon from '@material-ui/icons/Edit'
import DeleteIcon from '@material-ui/icons/Delete'

import { networking } from 'Models'

const styles = theme => ({
    popover: {
        pointerEvents: 'none',
    },
    paper: {
        padding: theme.spacing(1),
    },
    tip: {
        fontSize: '10px',
    },
    listItem: {
        fontSize: '15px',
        minHeight: '15px',
    },
})

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})(props => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
))

class PerkRow extends Component {
    state = {
        popAnchorEl: null,
        menuAnchorEl: null,
    }

    constructor(props) {
        super(props)
        this.state.switch = this.props.perk.switch
    }

    handlePopoverOpen = event => this.setState({popAnchorEl: event.currentTarget})
    handlePopoverClose = () => this.setState({popAnchorEl: null})
    handleMenuOpen = event => this.setState({menuAnchorEl: event.currentTarget})
    handleMenuClose = (callback=null, keepID=true) => () => {
        if (callback) {
            if (keepID) {
                callback(this.props.perk)
            } else {
                callback({
                    ...this.props.perk,
                    id: null,
                })
            }
        }
        this.setState({menuAnchorEl: null})
    }

    handlePerkSwitch = event => {
        const checked = event.target.checked
        networking.patchPerk({
            id: this.props.perk.id,
            switch: checked,
        }).then(() => {
            this.setState({switch: checked})
        })
    }
    
    render() {
        const { classes, perk, onEdit, onDuplicate, onDelete } = this.props
        const { popAnchorEl, menuAnchorEl } = this.state

        var last = '-'
        if (perk.last) {
            var date = new Date(perk.last)
            last = '' + date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) +
                ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2) + ':' + ('0' + date.getSeconds()).slice(-2)
        }

        return (
            <TableRow key={perk.id}>
                <TableCell>{perk.perk_id}</TableCell>
                <TableCell>{perk.campaign_title}</TableCell>
                <TableCell>{perk.perk_title}</TableCell>
                <TableCell>$ {perk.amount / 100}</TableCell>
                <TableCell>{perk.username}</TableCell>
                <TableCell>{perk.frequency}</TableCell>
                <TableCell>{perk.threshold}</TableCell>
                <TableCell>{perk.increment}</TableCell>
                <TableCell>
                    <Typography
                        aria-owns={'ks-perk-status-' + perk.id}
                        aria-haspopup="true"
                        onMouseEnter={this.handlePopoverOpen}
                        onMouseLeave={this.handlePopoverClose}
                    >{perk.status}</Typography>
                    <Popover 
                        id={'ks-perk-status-' + perk.id}
                        className={classes.popover}
                        classes={{
                            paper: classes.paper,
                        }}
                        open={popAnchorEl != null}
                        anchorEl={popAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        onClose={this.handlePopoverClose}
                        disableRestoreFocus
                    >
                        <Typography className={classes.tip}>Current: {perk.claimed} / {perk.available}</Typography>
                        <Typography className={classes.tip}>Last run: {last}</Typography>
                        <Typography className={classes.tip}>{perk.info}</Typography>
                    </Popover>
                </TableCell>
                <TableCell><Switch color="primary" checked={this.state.switch} onChange={this.handlePerkSwitch}/></TableCell>
                <TableCell>
                    <IconButton aria-controls={'ks-perk-menu-' + perk.id} aria-haspopup="true" onClick={this.handleMenuOpen}><MoreIcon /></IconButton>
                    <StyledMenu
                        id={'ks-perk-menu-' + perk.id}
                        anchorEl={menuAnchorEl}
                        keepMounted
                        open={Boolean(menuAnchorEl)}
                        onClose={this.handleMenuClose(null)}
                    >
                        <MenuItem onClick={this.handleMenuClose(onEdit)}>
                            <ListItemIcon style={{minWidth: '32px'}}><EditIcon /></ListItemIcon>
                            <ListItemText><Typography className={classes.listItem}>Edit</Typography></ListItemText>
                        </MenuItem>
                        <MenuItem onClick={this.handleMenuClose(onDuplicate, false)}>
                            <ListItemIcon style={{minWidth: '32px'}}><AddIcon /></ListItemIcon>
                            <ListItemText><Typography className={classes.listItem}>Duplicate</Typography></ListItemText>
                        </MenuItem>
                        <MenuItem onClick={this.handleMenuClose(onDelete)}>
                            <ListItemIcon style={{minWidth: '32px'}}><DeleteIcon color="error" /></ListItemIcon>
                            <ListItemText style={{color: 'red'}}><Typography className={classes.listItem}>Remove</Typography></ListItemText>
                        </MenuItem>
                    </StyledMenu>
                </TableCell>
            </TableRow>
        )
    }
}


export default withStyles(styles)(PerkRow)
