import React, { Component } from 'react'
import { Grid, Table, TableBody, TableHead, TableRow, Paper, withStyles, IconButton, Typography } from '@material-ui/core'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Input, LinearProgress, CircularProgress } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import RefreshIcon from '@material-ui/icons/Refresh'
import { FixedSpace, CommonAlert, StyledTableCell } from 'Utils'
import ImageRow from './ImageRow'
import { networking } from 'Models'


const style = theme => ({
    textField: {
        marginBottom: theme.spacing(1)
    },
    menu: {
        width: 200,
    },
})


class PublicImages extends Component {
    state = {
        images: [],
        editingDialog: false,
        deletingImage: null,
        alert: false,
        editingImage: null,
        editingNote: '',
        saving: false,
        percent: 0,
        error: null,
    }

    componentDidMount() {
        this.refresh()
    }

    refresh = () => {
        networking.fetchPublicImages().then(images => {
            this.setState({images})
        })
    }

    handleDeleteImage = () => {
        networking.deletePublicImage(this.state.deletingImage.id).then(() => {
            this.refresh()
        }).catch(e => {
            console.log(e)
        })
        this.handleAlertClose()
    }

    handleAlertOpen = image => () => {
        this.setState({alert: true, deletingImage: image})
    }

    handleAlertClose = () => {
        this.setState({alert: false})
    }

    handleEditingDialogOpen = () => {
        this.setState({editingDialog: true})
    }
    handleEditingDialogClose = () => this.setState({editingDialog: false})

    handleSaving = () => {
        if (this.state.editingImage === null || this.state.editingNote === '') {
            return
        }
        this.setState({saving: true, error: null})
        networking.uploadPublicImage(this.state.editingImage, this.state.editingNote, event => {
            var percentCompleted = Math.round((event.loaded * 100) / event.total)
            this.setState({percent: percentCompleted})
        }).then(() => {
            this.setState({editingDialog: false, saving: false})
            this.refresh()
        }).catch(e => {
            this.setState({saving: false, error: e.message})
        })
    }

    handleFileSelect = event => {
        this.setState({editingImage: event.target.files[0]})
    }

    handleEditing = event => {
        this.setState({editingNote: event.target.value})
    }

    render() {
        const { editingDialog, editingNote, percent, saving, error } = this.state
        return (
            <Grid container direction="column" justify="center" alignItems="center">
                <Grid item><FixedSpace size="xl3" /></Grid>
                <Grid item>
                    <Typography variant="h4">Public Images</Typography>
                </Grid>
                <Grid item><FixedSpace size="md" /></Grid>
                <Grid item style={{width: '100%', textAlign: 'right'}}>
                    <IconButton onClick={this.refresh}><RefreshIcon color="primary" /></IconButton>
                    <IconButton onClick={this.handleEditingDialogOpen}><AddIcon color="primary" /></IconButton>
                </Grid>
                <Grid item style={{width: '100%'}}>
                    <Paper style={{overflow: 'hidden'}}>
                        <Table size="small">
                            <TableHead>
                                <TableRow>
                                    <StyledTableCell>Image ID</StyledTableCell>
                                    <StyledTableCell>Image Path</StyledTableCell>
                                    <StyledTableCell>Note</StyledTableCell>
                                    <StyledTableCell></StyledTableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.images.map(image => {
                                    return (<ImageRow image={image} key={image.id} onDelete={this.handleAlertOpen} />)
                                })}
                            </TableBody>
                        </Table>
                    </Paper>
                </Grid>
                <CommonAlert 
                    title="Delete Image"
                    content="This action CANNOT be restored! Are you sure to delete this perk?"
                    left="Delete"
                    right="Cancel"
                    open={this.state.alert}
                    onClose={this.handleAlertClose}
                    onLeft={this.handleDeleteImage}
                    onRight={this.handleAlertClose}
                />
                <Dialog open={editingDialog} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">Top-up Parameter</DialogTitle>
                    <DialogContent>
                        <TextField variant="outlined" InputLabelProps={{shrink: true}}  margin="dense" id="note" label="Note" value={editingNote} onChange={this.handleEditing} fullWidth />
                        <Input type="file" onChange={this.handleFileSelect}/>
                        {error && <Typography color="error">{error}</Typography>}
                        <FixedSpace size="sm" />
                        <LinearProgress variant="determinate" value={percent} hidden={!saving}/>
                    </DialogContent>
                    <DialogActions style={{padding: '15px'}}>
                        <Button onClick={this.handleEditingDialogClose} color="primary">
                            Cancel
                        </Button>
                        <Button variant="contained" onClick={this.handleSaving} color="primary" disabled={saving}>
                            Save
                            {saving && <CircularProgress size={24} style={{position: 'absolute'}} color="secondary"/>}
                        </Button>
                    </DialogActions>
                </Dialog>
                <Grid item><FixedSpace size="xl2" /></Grid>
            </Grid>
        )
    }
}

export default withStyles(style)(PublicImages)
